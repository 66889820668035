body {
    font-family: "Ubuntu", sans-serif;
    color: #4c4c4c;
    z-index: 99;
    margin-top: 120px;
}

.App {
    text-align: center;
}

a {
    text-decoration: none !important;
}

::-webkit-scrollbar-track {
    background: white;
}

::-webkit-scrollbar-thumb {
    background: #00215E;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #dc242a;
}

::-webkit-scrollbar {
    width: 8px;
}

.titleNotFound {
    font-size: 50px;
}

.subTitle404 {
    font-size: 60px;
}

.kontaktTitle {
    font-size: 40px;
    padding: 30px 0 30px 0;
    text-align: center;
    text-transform: uppercase;
}

.ml-auto {
    margin-left: 0 !important;
}

.navbar-toggler {
    background-color: #dc242a !important;
    border-radius: 0;
}

.navbar-light .navbar-toggler {
    margin: 0 auto;
}

/* .navbar{
  box-shadow: 0px 0px 10px 0px rgba(12,12,12,0.5);
} */
.vlajkaENG {
    text-align: center;
}

@media (min-width: 1400px) and (max-width: 1920px) {
    .navbarChange.scrolled {
        background-color: transparent !important;
        box-shadow: none;
        transition-duration: 2s;
        margin-left: -350px;
        margin-top: -25px;
    }

    .navBrandOut.out {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 2s, opacity 2s linear;
    }
}

.hHmQqx .navContainer {
    border-radius: 3px;
}

.navShadow {
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.4));
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}

.navbar-expand-lg .navbar-nav {
    justify-content: center;
}

.videoBox {
    width: 100% !important;
    margin: 0 auto;
}

.col-lg-12 {
    padding-right: 0;
    padding-left: 0;
}

#techStroje {
    width: 100%;
    height: 270px;
    padding-bottom: 30px;
}

.homeFilter {
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.4));
}

.techTitle {
    text-transform: uppercase;
    text-align: center;
    color: #4c4c4c;
    font-weight: 500;
    padding-top: 30px;
    /* padding-bottom: 30px; */
}

.mainOV {
    position: absolute;
    top: 55%;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, -30px);
    background-color: #00215E;
    opacity: 0.8;
    width: 92%;
    margin: 0 auto;
    z-index: 1;
}

.centered {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -40%);
    background-color: transparent;
    width: 92%;
    z-index: 2;
}

.centered h2, .centered p {
    font-weight: 500;
}

.centered h2 {
    color: #dc242a;
    text-align: center;
    font-size: 28px;
}

.centered p {
    color: #dc242a;
    text-align: center;
    font-size: 20px;
}

.centered:hover {
    color: #dc242a;
}

#oSpolBox {
    padding-top: 30px;
    padding-bottom: 30px;
}

.oSpolimg {
    width: 100%;
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.4));
}

h2.oSpolTitle {
    border-bottom: 2px solid #dc242a !important;
    padding: 0 0 10px 0;
    width: 60%;
    color: #4c4c4c;
    margin: 0 auto;
    text-align: center;
}

.oSpolText {
    padding-top: 20px;
}

.certif {
    width: 100%;
    padding-bottom: 7px;
}

.certTitle {
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;
    padding-top: 30px;
}

.certPad {
    padding-top: 30px;
}

.imgProjekty img{
    width: 75%;
    background-color: white;
    padding-right: 5px;
}

.projektEU {
    width: 30%;
}

@media only screen and (max-width: 1025px) {
    .centered {
        width: 90%;
    }

    .mainOV {
        width: 90%;
        margin: 0 auto;
    }

    .centered h2 {
        font-size: 24px;
    }

    .centered p {
        font-size: 19px;
    }
}

@media only screen and (max-width: 769px) {
    .centered {
        width: 87%;
    }

    .mainOV {
        width: 87%;
        margin: 0 auto;
    }

    .centered h2 {
        font-size: 19px;
    }

    .centered p {
        font-size: 16px;
    }

    #techStroje {
        width: 100%;
        height: 200px;
        padding-bottom: 30px;
    }

    h2.oSpolTitle {
        text-align: center;
        width: 100%;
    }

    .oSpolText {
        width: 90%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .oSpolTitle_TM {
        width: 287px !important;
    }

    #oSpolVideo {
        width: 288px !important;
        height: 160px !important;
    }

    #G_map {
        width: 100% !important;
        height: 250px !important;
    }
}

@media only screen and (max-width: 426px) {
    .centered {
        width: 93%;
    }

    .mainOV {
        width: 93%;
    }

    .centered h2 {
        font-size: 25px;
    }

    .centered p {
        font-size: 20px;
    }

    .techTitle {
        font-size: 26px;
    }

    #techStroje {
        width: 100%;
        height: 270px;
        padding-bottom: 30px;
    }

    .certTitle {
        font-size: 30px;
    }

    .oTitle {
        font-size: 20px !important;
        margin-top: 50px !important;
    }

    #oSpolVideo {
        width: 288px !important;
        height: 160px !important;
    }

}

@media only screen and (max-width: 376px) {
    .oTitle {
        font-size: 20px !important;
    }

    .centered {
        width: 93%;
    }

    .mainOV {
        width: 92%;
    }

    .centered h2 {
        font-size: 25px;
    }

    .centered p {
        font-size: 20px;
    }

    #oSpolVideo {
        width: 288px !important;
        height: 160px !important;
    }
}

#G_map {
    width: 100%;
    height: 500px;
    border: none;
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.4));
}

.container-fluid {
    margin-top: -7px;
}

table, th, td {
    /*border: 1px solid rgba(0, 0, 0, .2);*/
}

table {
    border: none;
}

table.hodinyTable {
    margin: 0 auto;
    margin-top: 10%;
}

td {
    color: white;
    font-weight: 500;
    font-size: 25px;
    width: 30%;
    /*border: 1px solid rgba(0, 0, 0, .5);*/
}

h3.hodinyTitle, h3.videoTitle, .dotazTitle {
    color: white;
    padding-bottom: 10px;
    border-bottom: 2px solid #dc242a !important;

    width: 100%;
}

.contactBox {
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px) {
    h3.hodinyTitle {
        font-size: 22px;
    }
}

@media screen and (max-width: 768px) {
    h3.hodinyTitle, h3.videoTitle, h3.dotazTitle {
        font-size: 20px;
    }

    td {
        font-size: 17px;
        padding: 0 0 0 0;
    }

    .contactBox {
        padding-top: 6%;
    }
}

@media screen and (max-width: 425px) {
    td {
        font-size: 16px;
    }

    .contactBox {
        padding-top: 0;
    }
}

p.odkazTechnologie {
    color: white;
    text-align: center;
}

p.odkazTechnologie:hover {
    color: #dc242a;
}

/* START KONTAKT */
.hrCustom {
    background-color: #4c4c4c;
    border: 1px solid #4c4c4c;
    width: 50%;
    margin: 0 auto;
}

.hrCustomHP {
    background-color: #4c4c4c;
    border: 1px solid #4c4c4c;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.hrCustomTlmic {
    background-color: #4c4c4c;
    border: 1px solid #4c4c4c;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.conContainer {
    text-align: center;
}

.imgCon {
    width: 350px;
    /*height: 202px;*/
}

.card-title {
    background-color: #00215E;
    color: white;
    width: 50%;
    text-align: center;
}

.card-titleK4, .card-titleK5 {
    background-color: #00215E;
    color: white;
    width: 70%;
    text-align: center;
}

.card-text {
    background-color: #dc242a;
    color: white;
    width: 30%;
    text-align: center;
}

.card-textK2 {
    background-color: #dc242a;
    color: white;
    width: 60%;
    text-align: center;
}

.card-textK3 {
    background-color: #dc242a;
    color: white;
    width: 40%;
    text-align: center;
}

.card-textK4 {
    background-color: #dc242a;
    color: white;
    width: 40%;
    text-align: center;
}

.card-textK5 {
    background-color: #dc242a;
    color: white;
    width: 60%;
    text-align: center;
}

.telCon, .mailCon {
    color: #dc242a;
    font-weight: 500;
}

.telCon:hover, .mailCon:hover {
    color: #00215E;
}

.phoneCon, .enveCon {
    color: #1a1919;
}

.img-thumbnail {
    padding: 0.5px;
    border: 1px solid white;
    border-radius: 0;
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.4));
}

.conInfo {
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    border-right: 2px solid #4c4c4c;
    padding-right: 20px;
}

.namePK {
    position: relative;
    transform: translate(160px, -40px);
    padding: 5px 3px;
}

.funPK {
    position: relative;
    transform: translate(220px, -55px);
}

.namePB {
    position: relative;
    transform: translate(160px, -40px);
    padding: 5px 3px;
}

.funPB {
    position: relative;
    transform: translate(115px, -55px);
}

.nameJS {
    position: relative;
    transform: translate(160px, -40px);
    padding: 5px 3px;
}

.funJS {
    position: relative;
    transform: translate(185px, -55px);
}

.nameMB {
    position: relative;
    transform: translate(90px, -40px);
    padding: 5px 3px;
}

.funMB {
    position: relative;
    transform: translate(185px, -52px);
}

.nameVK {
    position: relative;
    transform: translate(90px, -40px);
    padding: 5px 3px;
}

.funVK {
    position: relative;
    transform: translate(115px, -52px);
}

.fa-building, .fa-map, .fa-info {
    color: #00215E;
}

.conInfoLast {
    font-size: 23px;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    border-right: none;
}

.colDot {
    text-align: left;
}

.dotLink {
    color: #dc242a;
}

/* END KONTAKT */
.conFluid {
    text-decoration: none;
    display: inline-block;
    padding: 0 15px;
    margin: 0 auto;
    text-align: center;
}

ul {
    text-align: center;
    padding: 15px 15px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    li, .conFluid {
        width: 100%;
        display: flex;
    }
}

@media (min-width: 375px) and (max-width: 1024px) {
    .conInfo {
        border-right: none;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .conFluid {
        padding: 0 5px;
    }

    table.hodinyTable {
        margin-top: 0 !important;
    }

    .footerVideo {
        margin-top: 0 !important;
    }
}

.footerVideo {
    margin-top: 5%;
}

#cd_timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}

@media (min-width: 1024px) {
    #cd_timeline {
        margin-top: 0;
        margin-bottom: 3em;
    }
}

#cd_timeline .timeline_line {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    background: #00215E;
}

.oTitle {
    text-align: center;
    font-size: 34px;
    background: #00215E;
    color: white;
    width: 60%;
    border: 2px solid #00215E;
    border-radius: 5px;
    margin: 30px auto;
    box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.5);
}

.imgHis {
    width: 255px;
    height: 191px;
    padding-bottom: 15px;
}

.ekoMapAddress {
    color: #00215E;
}

.ekoMapAddress:hover {
    color: #dc242a;
}

.sideBox {
    list-style: none;
}

.sideNavbar {
    font-size: 17px;
    color: #dc242a;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.mainTitleTech {
    font-size: 30px;
    color: white;
    font-weight: 500;
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.4));
    text-align: center;
    margin: 60px auto;
    margin-bottom: 30px;
    padding: 0px 0px 10px 0px;
    background-color: #dc242a;
    border-style: solid;
    border-width: 4px 4px 4px 4px;
    border-color: #dc242a;
    border-radius: 30px 0px 38px 0px;
    width: 50%;
}

.mainTitleTech::after {
    content: "";
    display: block;
    clear: both;
    width: 85%;
    height: 0;
    border-bottom: 2px solid white;
    margin: 0 auto;
}

.titleMachine {
    font-size: 21px;
    font-weight: 500;
    color: #7a7a7a;
    text-align: center;

    padding: 10px;
}

.machineNote {
    font-size: 19px;
    color: #7a7a7a;
    text-align: center;
}

.techBox {
    margin: 60px auto;
    padding-left: 50px;
}

.delTitleTech {
    width: 30%;
}

.fScreenBrus {
    width: 20%;
}

.fScreenVystK, .fScreenOmielZar {
    width: 35%;
}

.dotacieTitle {
    padding-bottom: 0 !important;
}

.hrCustomDotacie {
    background-color: #4c4c4c;
    border: 1px solid #4c4c4c;
    width: 30%;
    margin: 0 auto;
}

.hrCustomVO {
    background-color: #4c4c4c;
    border: 1px solid #4c4c4c;
    width: 50%;
    margin: 0 auto;
}

.obstaravanieVyska {
    height: 670px;
}

.hrCustomGal {
    background-color: #4c4c4c;
    border: 1px solid #4c4c4c;
    width: 60%;
    margin: 0 auto;
}

.hrCustomContact {
    background-color: #4c4c4c;
    border: 1px solid #4c4c4c;
    width: 40%;
    margin: 10px auto;
}

.dotIMG1, .dotIMG2 {
    padding-top: 30px;
}

.conInfo_phone_email {
    position: relative;
    transform: translate(0, -50px);
    text-align: left;
}

.conBoxInfo {
    text-align: center;
}

@media (min-width: 374px) and (max-width: 426px) {
    .conInfo_phone_email {
        text-align: center;
    }

    .conSubTitle {
        font-size: 30px;
    }

    .hrCustomGal {
        width: 30%;
    }

    .sideNavbar {
        text-align: center;
    }

    .techBox {
        margin: 25px auto;
        margin-bottom: 0;
        padding-left: 15px;
    }

    .mainTitleTech {
        font-size: 25px;
        width: 100%;
        margin: 15px auto;
    }

    .titleMachine {
        font-size: 20px;
    }

    .mobileBrus {
        width: 50%;
    }

    .mobileTitle {
        width: 80%;
    }

    .left_TM::before {
        top: 27px !important;
    }

    .right_TM::before {
        top: 27px !important;
    }

    .container_TM::after {
        top: 24px !important;
    }

    h2.oSpolTitle {
        margin-top: 30px;
    }

    .kontaktTitle {
        margin-top: 30px;
    }

    .dotacieTitle {
        padding-top: 50px !important;
    }

    .certTitle {
        padding-top: 50px !important;
    }

    .mt-title {
        margin-top: 30px;
        font-size: 30px;
    }

    .funPK {
        transform: translate(228px, -55px);
    }

    .funPB {
        transform: translate(112px, -55px);
    }

    .funJS {
        transform: translate(188px, -55px);
    }

    .nameMB {
        transform: translate(80px, -40px);
    }

    .funMB {
        transform: translate(186px, -52px);
    }

    .nameVK {
        transform: translate(80px, -40px);
    }

    .funVK {
        transform: translate(109px, -52px);
    }

    .titleGal {
        margin-top: 30px;
    }

    .navLogo {
        margin: 0 25px !important;
    }

    .contactBox {
        position: relative;
        transform: translate(-50%, -60%);
    }

    #homePageFade, #oSpolBox, #mapFade, #oSpolFade, #oSpolFadeR, #oSpolFadeL, #oSpolFadeVideo {
        visibility: visible !important;
    }

    .mainTitleTech::after {
        width: 25% !important;
    }

    .signCompanyFooter {
        text-align: center !important;
    }

    .signMe {
        text-align: center !important;
    }

    .signCompanyFooter {
        padding: 0 !important;
    }

    .parag404L {
        left: 0 !important;
        transform: translate(0%, 0%) !important;
        padding-left: 10px;
    }

    .parag404R {
        right: 0 !important;
        transform: translate(-5%, -95%) !important;
    }
}

#homePageFade, #oSpolBox, #mapFade, #oSpolFade, #oSpolFadeR, #oSpolFadeL, #oSpolFadeVideo {
    visibility: hidden;
}

.signCompanyFooter {
    padding-left: 15px;
}

@media (min-width: 767px) and (max-width: 769px) {
    .mobileTitle {
        width: 65%;
    }

    .mainTitleTech {
        width: 90%;
    }

    .mainTitleTech:after {
        width: 95%;
    }

    .mobileBrus {
        width: 60%;
    }
}

.imgGal {
    position: relative;
    width: 100%;
    margin-bottom: 25px;
    transition: transform .3s;
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.4));
}

.imgGal:hover {
    -ms-transform: scale(1.7); /* IE 9 */
    -webkit-transform: scale(1.7); /* Safari 3-8 */
    transform: scale(1.7);
    z-index: 99;
}

.galTitle {
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    padding: 30px 0 30px 0;
}

.timeline_TM {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.timeline_TM::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #00215E;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    z-index: -1;
}

.container_TM {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.4));
}

.container_TM::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -12px;
    background-color: #dc242a;
    border: 4px solid white;
    top: 24px;
    border-radius: 50%;
    z-index: 1;
}

.left_TM {
    left: 0;
}

.right_TM {
    left: 50%;
}

.left_TM::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 27px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #dc242a;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #dc242a;
}

.right_TM::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 27px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #dc242a;
    border-width: 10px 10px 10px 0;
    border-color: transparent #dc242a transparent transparent;
}

.right_TM::after {
    left: -13px;
}

.content_TM {
    padding: 15px 10px;
    position: relative;
    border-radius: 6px;
}

.content_TM_video {
    padding: 15px 6px;
    position: relative;
    border-radius: 6px;
}

@media screen and (max-width: 600px) {
    .timeline_TM::after {
        left: 31px;
    }

    .container_TM {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    .container_TM::before {
        left: 60px;
        border: medium solid #dc242a;
        border-width: 10px 10px 10px 0;
        border-color: transparent #dc242a transparent transparent;
    }

    .left_TM::after, .right_TM::after {
        left: 18px;
    }

    .right_TM {
        left: 0%;
    }
}

.oSpolTitle_TM {
    background-color: #00215E;
    border-radius: 4px;
    font-size: 20px !important;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.fill img {
    object-fit: cover;
    width: 360px;
    height: 100%;
}

.animated {
    -webkit-animation-duration: 1.7s;
    animation-duration: 1.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


/*GearSpin*/
.gearSpin {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4500ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4500ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 4500ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 4500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*End GearSpin*/
.parag404L {
    font-size: 170px;
    left: 385px;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    top: 50%;
}

.parag404R {
    font-size: 170px;
    right: 285px;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    top: 50%;
}

.homeBtn404 {
    font-size: 17px;
    background-color: #00215E;
    color: white;
    border-radius: 3px;
    padding: 15px;
    font-weight: 500;
    filter: drop-shadow(2px 4px 6px rgba(46, 49, 49, 0.6));
}

.homeBtn404:hover {
    color: #dc242a;
}

.st0 {
    fill: #4c4c4c !important;
}