body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Prekrytie pozadia pre modál */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* polopriesvitné tmavé pozadie */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1031; /* vyššia vrstva nad obsahom stránky */
}

/* Obsah modálneho okna */
.modal-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    max-width: 60%;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

/* Obrázok v modále */
.modal-content img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
}

/* Tlačidlo na zatvorenie */
.modal-content button {
    padding: 5px;
    background-color: #00215E;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.2s all ease-in-out;
}

.modal-content button:hover {
    background-color: #dc242a;
}

.modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
        width: 45%;
        font-size: 12px;
    }
}

.detail-tlmic {
    p {
        color: #00215E;
    }

    .imgGal:hover {
        transform: scale(1.2);
    }
}

.tlmic-ul {
    padding: 0;
    text-align: left;
    list-style: none;
    color: #00215E;
}

.o-tlmici .oSpolTitle {
    width: 90%;
}

.o-tlmici {
    .obrazok {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.button-tlmic {
    display: flex;

    a {
        color: #dc242a;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;
        transition: 0.2s all ease-in-out;

        i {
            margin-left: 0.5rem;
        }
    }

    button:hover {
        background-color: #00215E;
    }
}

.stiahnutie {
    margin-bottom: 1rem;

    .link {
        color: #dc242a;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;
        transition: 0.2s all ease-in-out;
    }

    .link:first-child {
        margin-right: 1rem;
    }

    .link:hover {
       color: #00215E;
    }
}

@media (width <= 992px) {
    .tlmic-detail-kontajner {
        flex-direction: column-reverse;
    }

    .button-tlmic {
        justify-content: center;
        margin-bottom: 1rem;
    }
}

@media (width >= 768px) {
    .modal-buttons {

        button {
            width: 25%;
        }
    }
}